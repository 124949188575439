<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" title="Opportunità da chiudere importa" size="lg" hide-footer>
            <b-table responsive :fields="fields" :items="data.opportunities" :sticky-header="(winHeight - 475) + 'px'">
                <template v-slot:cell(id)="row">
                    <b-btn variant="primary" size="sm" @click="saveData(row.value)">Importa</b-btn>
                </template>
            </b-table>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditsopportunitytobeclosedimport",
    data() {
        return {
            show: false,
            data: null,
            fields: [{
                key: 'audits_id',
                label: "Audit",
            },{
                key: "certificationbody",
                label: "Ente",
            },{
                key: 'plandate',
                label: "Data",
                formatter: value => {
                    if (value == null) return null;
                    var d = new Date(value);
                    return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
                }
            },{
                key: "opportunity",
                label: "Opportunità",
            },{
                key: "standardspoints",
                label: "Punti",
            },{
                key: "id",
                label: "Importa",
            }]
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {
        loadData( id ){
            this.openHttp("audits/findingtobeclosed/import", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData( id ){
            this.saveHttp("audits/findingtobeclosed/importsave", { audits_id: this.data.audits_id, id: id }).then((result) => {
                if ( result !== null ) {
                    this.loadData(this.data.audits_id);
                    this.$emit('update');
                }
            })
        }
    }
}
</script>