<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" :title="'Punto di forza - ' + data.strength.id" size="lg">
            <b-form-group label="Punto di forza">
                <b-row>
                    <b-col v-for="(item, index) in data.strength.languages" :key="index">
                        <b-input-group :prepend="item.lang">
                            <b-form-textarea v-model="item.strength" rows="3"/>
                        </b-input-group>
                        <b-btn variant="secondary" size="sm" class="mt-2" v-show="item.strength !== null && item.strength === item.strength.toUpperCase()" @click="item.strength = item.strength.toLowerCase()">Minuscolo</b-btn>
                    </b-col>
                </b-row>
            </b-form-group>
            <b-form-group label="Punti norma">
                <b-form-checkbox-group v-model="data.strength.standardspoints">
                    <div v-for="(item, index) of data.points" :key="index">
                        <strong>{{ item.standard }}: </strong>
                        <b-form-checkbox v-for="(item2, index2) of item.points" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                    </div>
                </b-form-checkbox-group>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" :disabled="data.strength.standardspoints.length === 0" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.strength.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditstrength",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData( auditsId, id ){
            this.openHttp("audits/strength", { audits_id: auditsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/strength/save", this.data.strength).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("audits/strength/delete", { audits_id: this.data.strength.audits_id, id: this.data.strength.id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>