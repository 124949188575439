<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Preventivo - ' + data.quote.id" @hidden="closeForm()" @close="closeForm()">
      <company ref="company"/>
      <contract ref="contract"/>
      <quotestep ref="step" @update="loadData(data.quote.id)"/>
      <quotehistory ref="history" @update="loadData(data.quote.id)"/>
      <quotedocument ref="document" @update="loadData(data.quote.id)"/>
      <mailnew ref="mailnew" @update="loadData(data.quote.id)"/>
      <mail ref="mail" @update="loadData(data.quote.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Preventivo" active>
            <b-row>
              <b-col lg="5">
                <b-form-group>
                  <label><b-link v-if="data.quote.companies_id > 0" @click="$refs.company.loadData(data.quote.companies_id)">Azienda</b-link><span v-else>Azienda</span></label>
                  <multiselect v-model="companies_idSupport" :options="data.companies" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.quote.companies_id = updateComboSimple(companies_idSupport)" @search-change="asyncCompanies"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Segnalatore">
                  <b-form-select v-model="data.quote.suppliers_id" :options="data.suppliers" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Ditta">
                  <b-form-select v-model="data.quote.internalcompanies_id" :options="data.internalcompanies" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Ente">
                  <b-form-select v-model="data.quote.certificationbodies_id" :options="data.certificationbodies" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="1">
                <b-form-group label="Lingua">
                  <b-form-select v-model="data.quote.languages_id" :options="data.languages" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Titolo">
              <b-input-group>
                <b-input-group-prepend v-show="level >= 3">
                  <b-btn variant="primary" @click="data.quote.title = data.servicestitle">Prepara titolo</b-btn>
                </b-input-group-prepend>
                <b-form-input v-model="data.quote.title"/>
              </b-input-group>
            </b-form-group>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Pagamento">
                  <b-form-select v-model="data.quote.invoicespaymenttypes_id" :options="data.invoicespaymenttypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Valuta">
                  <b-form-select v-model="data.quote.invoicesuniformsmonetary_id" :options="data.invoicesuniformsmonetary" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="1">
                <b-form-group label="% Iva">
                  <b-form-input type='number' step='0.01' v-model="data.quote.percentvat"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Servizi">
              <b-btn variant="primary" size="sm" @click="$refs.step.loadData(data.quote.id, 0)" v-if="level >= 3">Nuovo</b-btn>
              <b-table responsive class="mt-3" :items="data.steps" :tbody-tr-class="rowClassMargin" :fields="level >= 3 ? fields.steps3 : (level == 2 ? fields.steps2 : fields.steps1)" @row-clicked="$refs.step.loadData(data.quote.id, $event.id)">
                <template v-slot:cell(costs)="row">
                  <div v-for="(item, index) of row.value" :key="index">{{ item.val + ': ' + item.cost }}</div>
                </template>
              </b-table>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.quote.note" rows="2"/>
            </b-form-group>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Converti in contratto">
                  <b-form-select v-model="data.quote.convertcontract" :options="contractCombo" value-field="id" text-field="val" :disabled="data.quote.contracts_id > 0"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" v-if="data.quote.contracts_id > 0">
                <b-form-group>
                  <label><b-link @click="$refs.contract.loadData(data.quote.contracts_id)">Contratto</b-link></label>
                  <b-form-select v-model="data.quote.contracts_id" :options="data.contracts" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Storia" v-if="data.quote.id > 0">
            <b-btn variant="primary" size="sm" @click="$refs.history.loadData(data.quote.id, 0)">Nuova</b-btn>
            <b-table responsive :items="data.history" :fields="fields.history" class="mt-3" size="sm" @row-clicked="$refs.history.loadData(data.quote.id, $event.id)"/>
          </b-tab>
          <b-tab title="Email" v-if="data.quote.id > 0">
            <b-row class="mb-2">
              <b-col lg="4">
                <b-form-input v-model="fields.mailsCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table :sticky-header="(winHeight - 335) + 'px'" :tbody-tr-class="rowClassMails" responsive :items="data.mails" :fields="fields.mails" @row-clicked="$refs.mail.loadData($event.id)"/>
          </b-tab>
          <b-tab title="Documenti" v-if="data.quote.id > 0">
            <b-btn variant="success" class="mr-2" size="sm" @click="$refs.document.loadData(data.quote.id, 0)">Nuovo</b-btn>
            <b-btn variant="info" size="sm" @click="prepareDocuments(data.quote.id)">Genera</b-btn>
            <b-table class="mt-3" :sticky-header="(winHeight - 205) + 'px'" :tbody-tr-class="rowClassDocuments" responsive :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData(data.quote.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" v-show="row.item.datetime !== null" size="sm" @click="$refs.document.downloadData(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-modal v-if="showMail" v-model="showMail" title="Manda" size="lg">
            <b-table :items="data.contacts" :fields="fields.contacts" sortable="true">
              <template v-slot:cell(selected)="row">
                <b-form-checkbox v-model="row.item.selected"/>
              </template>
            </b-table>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="prepareMail">Prepara Mail</b-btn>
              </div>
            </template>
          </b-modal>        
          <b-btn variant="primary" v-show="data.quote.id > 0" class="mr-2" @click="loadData(data.quote.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.quote.id > 0" @click="deleteData">Elimina</b-btn>
          <b-btn variant="info" v-show="data.file > 0" class="mr-2" @click="$refs.document.downloadData(data.file)">Scarica</b-btn>
          <b-btn variant="primary" v-show="data.quote.id > 0" @click="showMail = true">Prepara Mail</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import quotestep from './quotescomponents/quotestep.vue';
import quotehistory from './quotescomponents/quotehistory.vue';
import quotedocument from './quotescomponents/quotedocument.vue';

export default {
  name: "quote",
  components: { quotestep, quotehistory, quotedocument },
  data() {
    return {
      show: true,
      data: null,
      companies_idSupport: [],
      showMail: false,
      contractCombo: [{
        id: null,
        val: "Da decidere"
      },{
        id: true,
        val: "Da fare contratto"
      },{
        id: false,
        val: "No non fare contratto"
      }],
      fields: {
        steps1: [{
          key: 'date',
          label: 'Data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'services',
          label: "Servizi",
        },{
          key: 'quotation',
          label: 'Preventivo',
        }],
        steps2: [{
          key: 'date',
          label: 'Data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'services',
          label: "Servizi",
        },{
          key: 'quotation',
          label: 'Preventivo',
        },{
          key: 'costs',
          label: "Spese",
          formatter: value => {
            return value.map( x => {
              return { id: x.id, val: this.data.coststypes.find(y => y.id === x.id).val, cost: x.cost };
            });
          }
        }],
        steps3: [{
          key: 'date',
          label: 'Data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'services',
          label: "Servizi",
        },{
          key: 'quotation',
          label: 'Preventivo',
        },{
          key: "costs",
          label: "Spese",
          formatter: value => {
            return value.map( x => {
              return { id: x.id, val: this.data.coststypes.find(y => y.id === x.id).val, cost: x.cost };
            });
          },
        },{
          key: 'hours',
          label: "Ore",
        },{
          key: "km",
          label: "Km",
        },{
          key: "margin",
          label: "Margine",
          formatter: (value, key, item) => {            
            var costs = ( item.hours * this.data.coefHours ) + ( item.km * this.data.coefKm ) + (item.costs.length > 0 ? item.costs.map(x => x.cost).reduce((accumulator, currentValue) => accumulator + currentValue) : 0)
            return Math.round((item.quotation - costs) * 100) / 100 + " (" + (item.quotation !== 0 ? Math.round((((item.quotation - costs) / item.quotation ) * 10000), 2) / 100 : 0) + "%)";
          }
        }],
        history: [{
          key: 'date',
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'user',
          label: 'Collaboratore',
        },{
          key: 'status',
          label: "Stato",
        },{
          key: 'note',
          label: "Note",
        }],
        contacts: [{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "contactname",
          label: "Nome",
          sortable: true
        },{
          key: "contact",
          label: "Mail",
          sortable: true
        },{
          key: "active",
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value === true ? "Attivo" : "Non attivo";
          },
        },{
          key: "job",
          label: "Mansione",
          sortable: true
        },{
          key: "note",
          label: "Note",
          sortable: true
        },{
          key: "areas",
          label: "Aree",
          sortable: true
        },{
          key: "selected",
          label: "",
          sortable: true
        }],
        mailsCriteria: null,
        mails: [{
          key: 'folder',
          label: "Cartella",
          sortable: true
        },{
          key: 'from',
          label: 'Da',
        },{
          key: 'to',
          label: 'A',
        },{
          key: 'subject',
          label: "Oggetto",
        },{
          key: 'datetime',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "datetime",
          label: "Aggiornamento",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      },
    }
  },
  computed: {
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "quotes").level;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    rowClassMails(item) {
      if ( item !== null ) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    rowClassMargin(item) {
      if ( this.level <= 2 ) return "";
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      var costs = ( item.hours * this.data.coefHours ) + ( item.km * this.data.coefKm ) + (item.costs.length > 0 ? item.costs.map(x => parseFloat(x.cost)).reduce(reducer) : 0)
      var margin = (item.quotation !== 0 ? Math.round((((item.quotation - costs) / item.quotation ) * 10000), 2) / 100 : 0);
      return "table-" + (margin >= 20 ? "success" : ( margin >= 0 ? "warning" : "danger"));
    },
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? "table-danger" : "";
    },
    loadData( id ){
      this.openHttp("quotes/quote", { id: id }).then((result) => {
        if ( this.data === null ) this.show = false;
        this.companies_idSupport = this.convertComboSimple( result.companies, result.quote.companies_id );
        this.data = result;
        this.show = true;
      })
    },
    asyncCompanies ( query ) {
      if ( query.length > 3 ){
        this.openHttp("quotes/quote/companies", { search: query }).then((result) => {
          for ( let item of result ) if ( this.data.companies.findIndex( x => x.id === item.id ) === -1 ) this.data.companies.push(item);
          this.data.companies = this.data.companies.sort((a,b) => ( a.val > b.val ) ? 1 : 0 ); 
        })
      }
    },
    prepareMail(){
      let contactsTos = [];
      let contactsNames = [];
      for (let item of this.data.contacts) {
        if ( item.selected === true ){
          contactsTos.push(item.contact);
          if (contactsNames.includes(item.contactname) === false) contactsNames.push(item.contactname);
        }
      }
      this.$refs.mailnew.loadData({
        id: 0,
        mailboxes_id: null,
        to: contactsTos.join(","),
        cc: "",
        bcc: "",
        subject: 'Preventivo: ' + this.data.quote.id,
        lockSubject: true,
        body: "",
        quotes_id: this.data.quote.id,
        documents: this.data.documents.map(x => x.id),
        parameters: [{
          key: "lastname",
          val: contactsNames
        },{
          key: "quotesid",
          val: this.data.quote.id
        }]
      });
    },
    prepareDocuments(id){
      this.saveHttp("quotes/document/generate", { id: id }).then((result) => {
        if (result !== null) this.loadData(id);
      })
    },
    saveData(){
      this.saveHttp("quotes/quote/save", this.data.quote).then((result) => {
        if (result !== null) {
          this.loadData(result);
          this.prepareDocuments(result); 
        }
      })
    },
    deleteData(){
      this.deleteHttp("quotes/quote/delete", { id: this.data.quote.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>