<template>
    <div>
        <b-modal v-if="data!= null" v-model="show" title="Veto">
            <b-form-group label="Persona">
                <b-form-select v-model="data.veto.users_id" :options="data.users" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Ruolo">
                <b-form-select v-model="data.veto.usersroles_id" :options="data.usersroles" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Standards">
                <b-form-checkbox-group v-model="data.veto.standards" :options="data.standards" value-field="id" text-field="val" stacked/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.veto.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "veto",
    data() {
        return {
            data: null,
            show: true
        }
    },
    methods: {
        loadData( auditsId, id ){
            this.openHttp("audits/veto", { audits_id: auditsId, id: id } ).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/veto/save", this.data.veto).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("audits/veto/delete", { id: this.data.veto.id, audits_id: this.data.veto.audits_id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>