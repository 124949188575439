<template>
  <div>
    <marketingcampaign ref="campaign" @update="loadCampaigns()"/>
    <marketingnote ref="note" @update="loadNotes()"/>
    <marketingtemplate ref="template" @update="loadTemplates()"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Campagne">
          <b-btn class="mr-2" variant="primary" @click="loadCampaigns()">Aggiorna</b-btn>
          <b-btn variant="success" @click="$refs.campaign.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 310) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="campaigns.items.data" :fields="campaigns.fields" :busy="campaigns.isBusy" :no-local-sorting="true" :sort-by.sync="campaigns.search.sortby" :sort-desc.sync="campaigns.search.sortdesc" @sort-changed="sortingChangeCampaigns" @row-clicked="$refs.campaign.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="campaigns.isBusy" :currentpage="campaigns.search.page" :total="campaigns.items.total" @updateCurrentPage="campaigns.search.page = $event" @loadData="loadCampaigns()"/>
        </b-tab>
        <b-tab title="Note / Scadenze" active>
          <b-modal v-model="notes.showFilter" title="Filtra">
            <b-form-group label="Azienda">
              <b-form-input v-model="notes.tempFilter.company"/>
            </b-form-group>
            <b-form-group label="Cognome">
              <b-form-input v-model="notes.tempFilter.lastname"/>
            </b-form-group>
            <b-form-group label="Mail">
              <b-form-input v-model="notes.tempFilter.mail"/>
            </b-form-group>
            <b-form-group label="Campagna">
              <b-form-select v-model="notes.tempFilter.marketingcampaigns_id" :options="addNullCombo(notes.filtersMenu.marketingcampaigns)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Scadenza">
              <b-form-select v-model="notes.tempFilter.done" :options="addNullCombo(doneCombo)" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetNotes()">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetNotes()">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadNotes()">Aggiorna</b-btn>
          <b-btn variant="secondary" class="mr-2" @click="filterOpenNotes()">Filtra</b-btn>
          <b-btn variant="success" @click="$refs.campaign.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 310) + 'px'" responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClassNotes" :items="notes.items.data" :fields="notes.fields" :busy="notes.isBusy" :no-local-sorting="true" :sort-by.sync="notes.search.sortby" :sort-desc.sync="notes.search.sortdesc" @sort-changed="sortingChangeNotes" @row-clicked="$refs.note.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="notes.isBusy" :currentpage="notes.search.page" :total="notes.items.total" @updateCurrentPage="notes.search.page = $event" @loadData="loadNotes()"/>
        </b-tab>
        <b-tab title="Templates">
          <b-btn class="mr-2" variant="primary" @click="loadTemplates()">Aggiorna</b-btn>
          <b-btn variant="success" @click="$refs.template.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 310) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="templates.items.data" :fields="templates.fields" :busy="templates.isBusy" :no-local-sorting="true" :sort-by.sync="templates.search.sortby" :sort-desc.sync="templates.search.sortdesc" @sort-changed="sortingChangeTemplates" @row-clicked="$refs.template.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(campaigns)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="templates.isBusy" :currentpage="templates.search.page" :total="templates.items.total" @updateCurrentPage="templates.search.page = $event" @loadData="loadTemplates()"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import marketingcampaign from './marketingcampaign';
import marketingnote from './marketingnote';
import marketingtemplate from './marketingtemplate';

export default {
  name: "marketing",
  components: {
    marketingcampaign,
    marketingnote,
    marketingtemplate
  },
  data() {
    return {
      campaigns: {
        isBusy: true,
        search: {
          page: 1,
          filters: {},
          sortby: "createdate",
          sortdesc: true,
        },
        fields: [{
          key: "campaign",
          label: "Campagna",
          sortable: true,
        },{
          key: "enabled",
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value === true ? 'Attiva' : 'Stoppata';
          },
        },{
          key: "createdate",
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'note',
          label: 'Note',
        },{
          key: 'status1',
          label: 'Creato',
        },{
          key: 'status2',
          label: 'Email',
        },{
          key: 'status3',
          label: 'Contatto',
        },{
          key: 'status4',
          label: 'Successo',
        },{
          key: 'status5',
          label: 'Fallito',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      doneCombo: [{
        id: false,
        val: "Da fare"
      },{
        id: true,
        val: "Fatte"
      }],
      notes: {
        isBusy: true,
        showFilter: false,
        tempFilter: {
          company: null,
          lastname: null,
          mail: null,
          marketingcampaigns_id: null,
          done: false,
        },
        filtersMenu: {
          marketingactionsstatuses: [],
          marketingcampaigns: [],
        },
        search: {
          page: 1,
          filters: {
            company: null,
            lastname: null,
            mail: null,
            marketingcampaigns_id: null,
            done: false
          },
          sortby: "datetime",
          sortdesc: false,
        },
        fields: [{
          key: "campaign",
          label: "Campagna",
          sortable: true,
        },{
          key: "company",
          label: "Azienda",
        },{
          key: "worker",
          label: "Riferimento",
        },{
          key: "fullname",
          label: "Incaricato",
          sortable: true,
        },{
          key: "datetime",
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          }
        },{
          key: "deadline",
          label: "Scadenza"
        },{
          key: 'donedatetime',
          label: "Stato",
          sortable: true,
          formatter: value => {
            if (value == null) return "Da fare";
            const today = new Date();
            var d = new Date(value);
            if (d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          }
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      templates: {
        isBusy: true,
        search: {
          page: 1,
          filters: {},
          sortby: "marketingtemplate",
          sortdesc: false,
        },
        fields: [{
          key: "marketingtemplate",
          label: "Template",
          sortable: true,
        },{
          key: "createdate",
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'note',
          label: 'Note',
        },{
          key: "campaigns",
          label: 'Campagne',
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadCampaigns();
    this.loadNotes();
    this.loadTemplates();
  },
  methods: {
    rowClassNotes(item) {
      if (item == null) return "";
      let today = new Date();
      let datetime = new Date(item.datetime);
      return today.getTime() >= datetime.getTime() ? "table-danger" : "";
    },
    loadCampaigns(){
      this.campaigns.isBusy = true;
      this.openHttp("marketing/campaigns", this.campaigns.search).then((result) => {
        this.campaigns.items = result;
        this.campaigns.isBusy = false;
      })
    },
    sortingChangeCampaigns(ctx) {
      this.campaigns.search.sortby = ctx.sortBy;
      this.campaigns.search.sortdesc = ctx.sortDesc;
      this.loadCampaigns();
    },
    loadNotes(){
      this.notes.showFilter = false;
      this.notes.isBusy = true;
      this.openHttp("marketing/notes", this.notes.search).then((result) => {
        this.notes.items = result;
        this.notes.isBusy = false;
      })
    },
    sortingChangeNotes(ctx) {
      this.notes.search.sortby = ctx.sortBy;
      this.notes.search.sortdesc = ctx.sortDesc;
      this.loadNotes();
    },
    filterOpenNotes(){
      this.openHttp("marketing/filters", null).then((result) => {
        this.notes.filtersMenu = result;
        this.notes.showFilter = true;
      })
    },
    filterSetNotes(){
      this.notes.search.page = 1;
      this.notes.search.filters = this.notes.tempFilter;
      this.loadNotes();
    },
    filterResetNotes(){
      this.notes.tempFilter = {
        company: null,
        lastname: null,
        mail: null,
        marketingcampaigns_id: null,
        done: false
      };
      this.filterSetNotes();
    },
    loadTemplates(){
      this.templates.isBusy = true;
      this.openHttp("marketing/templates", this.templates.search).then((result) => {
        this.templates.items = result;
        this.templates.isBusy = false;
      })
    },
    sortingChangeTemplates(ctx) {
      this.templates.search.sortby = ctx.sortBy;
      this.templates.search.sortdesc = ctx.sortDesc;
      this.loadTemplates();
    }
  }
}
</script>