<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Documento" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Nome file" v-if="data.document.filename != null">
                <b-form-input v-model="data.document.filename"/>
            </b-form-group>
            <b-form-group label="File">
                <b-form-file @change="fileChange"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" v-show="data.document.locked === false" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" class="mr-2" v-show="data.document.id > 0 && data.document.locked === false" @click="deleteData">Elimina</b-btn>
                    <b-btn variant="primary" v-show="data.document.id > 0 && data.document.locked === false" @click="lockData(data.document.id )">Blocca</b-btn>
                    <b-btn variant="warning" v-show="data.document.id > 0 && data.document.locked === true" @click="unlockData(data.document.id )">Sblocca</b-btn>
                </div>
            </template>
        </b-modal>
        <form method="post" action="/api/checklists/document/download" ref="downloadFormSubmit" target="_blank">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" ref="downloadFormId" name="id">
        </form>
        <form method="post" action="/api/checklists/document/downloadall" ref="downloadAllFormSubmit" target="_blank">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" ref="downloadAllFormId" name="id">
        </form>
    </div>
</template>

<script>
export default {
    name: "checklistsdocument",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        token (){
            return this.$store.getters.getToken;
        }
    },
    methods: {
        loadData( auditsId, id ){
            this.openHttp("checklists/document", { audits_id: auditsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        fileChange(event){
            var reader = new FileReader();
            if (event.target.files != null){
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = ()=> {
                    this.data.document.extension = event.target.files[0].name.split(".").slice(-1)[0].toLowerCase();
                    this.data.document.content = reader.result;
                }
            } else {
                reader.readAsDataURL(event.dataTransfer.files[0]);
                reader.onload = ()=> {
                    this.data.document.extension = event.dataTransfer.files[0].name.split(".").slice(-1)[0].toLowerCase();
                    this.data.document.content = reader.result;
                }
            }
        },
        downloadData(id){
            this.$refs.downloadFormId.value = id;
            this.$refs.downloadFormSubmit.submit();
        },
        downloadDataAll(id){
            this.$refs.downloadAllFormId.value = id;
            this.$refs.downloadAllFormSubmit.submit();
        },
        generateData(id){
            this.saveHttp("checklists/document/generate", { id: id });
        },
        saveData(){
            this.saveHttp("checklists/document/save", this.data.document).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        deleteData(){
            this.deleteHttp("checklists/document/delete", { id: this.data.document.id }).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        lockData(documentsId){
            this.saveHttp("checklists/document/lock", { id: documentsId }).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        unlockData(documentsId){
            this.saveHttp("checklists/document/unlock", { id: documentsId }).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        closeForm(reload = false){
            this.show = false;
            this.data = null;
            if (reload === true ) this.$emit("update");
        }
    },
}
</script>