<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" :title="'Non conformità da chiudere - ' + data.nonconformitytobeclosed.id" size="lg">
            <b-row>
                <b-col lg="6">
                    <b-form-group label="Non conformità">
                        <b-row>
                            <b-col v-for="(item, index) in data.nonconformitytobeclosed.languages" :key="index">
                                <b-input-group :prepend="item.lang">
                                    <b-form-textarea v-model="item.nonconformity" rows="2"/>
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
                <b-col lg="5">
                    <b-form-group label="Analisi delle cause">
                        <b-row>
                            <b-col v-for="(item, index) in data.nonconformitytobeclosed.languages" :key="index">
                                <b-input-group :prepend="item.lang">
                                    <b-form-textarea v-model="item.cause" rows="2"/>
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
                <b-col lg="1">
                    <b-form-group label="Tipologia">
                        <b-form-select v-model="data.nonconformitytobeclosed.auditsnonconformitiescertificationbodiestypes_id" :options="data.types" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6">
                    <b-form-group label="Trattamento">
                        <b-row>
                            <b-col v-for="(item, index) in data.nonconformitytobeclosed.languages" :key="index">
                                <b-input-group :prepend="item.lang">
                                    <b-form-textarea v-model="item.treatment" rows="2"/>
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group label="Azione correttiva">
                        <b-row>
                            <b-col v-for="(item, index) in data.nonconformitytobeclosed.languages" :key="index">
                                <b-input-group :prepend="item.lang">
                                    <b-form-textarea v-model="item.correctiveaction" rows="2"/>
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6">
                    <b-form-group label="Efficacia">
                        <b-form-select v-model="data.nonconformitytobeclosed.effectivenessdecision" :options="effectivenessdecisionCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group label="Efficacia - note">
                        <b-row>
                            <b-col v-for="(item, index) in data.nonconformitytobeclosed.languages" :key="index">
                                <b-input-group :prepend="item.lang">
                                    <b-form-textarea v-model="item.effectivenessnote" rows="2"/>
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Punti norma">
                <b-form-checkbox-group v-model="data.nonconformitytobeclosed.standardspoints">
                <div v-for="(item, index) of data.points" :key="index">
                    <strong>{{ item.standard }}: </strong>
                    <b-form-checkbox v-for="(item2, index2) of item.points" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                </div>
                </b-form-checkbox-group>
            </b-form-group>
            <b-form-group label="Veto">
                <b-table no-border-collapse responsive :items="data.veto" :fields="fields"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.nonconformitytobeclosed.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditnonconformitytobeclosed",
    data() {
        return {
            show: false,
            data: null,
            acceptdecisionCombo: [{
                id: null,
                val: 'In Corso ...'
            },{
                id: true,
                val: 'Accettata'
            },{
                id: false,
                val: 'Non accettata'
            }],
            effectivenessdecisionCombo: [{
                id: null,
                val: 'In Corso ...'
            },{
                id: true,
                val: 'Accettata'
            },{
                id: false,
                val: 'Non accettata'
            }],
            fields: [{
                key: 'datetime',
                label: 'Data'
            },{
                key: 'fullname',
                label: 'Veto'
            },{
                key: 'decision',
                label: 'Decisione'
            },{
                key: 'note',
                label: "Note"
            }]
        }
    },
    methods: {
        loadData( auditsId, id ){
            this.openHttp("audits/nonconformitytobeclosed", { audits_id: auditsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/nonconformitytobeclosed/save", this.data.nonconformitytobeclosed).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("audits/nonconformitytobeclosed/delete", { audits_id: this.data.nonconformitytobeclosed.audits_id, id: this.data.nonconformitytobeclosed.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        saveVeto(auditsId, id, decision, note){
            this.saveHttp("audits/nonconformitytobeclosed/vetosave", { audits_id: auditsId, id: id, decision: decision, note: note }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        unlockVeto(auditsId, id){
            this.saveHttp("audits/nonconformitytobeclosed/vetounlock", { audits_id: auditsId, id: id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>